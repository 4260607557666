import React, { useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { FolderPermission } from '../../../models/Settings';
import NewFolderForm, { CreateFolderRequest } from './NewFolderForm';
import { Modal } from '@prio365/prio365-react-library';
import {
  checkFileNameExists,
  debouncedInputChange,
  folderNameRegex,
} from '../../documents/util';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.old.spacing.unit(1),
  },
}));

interface NewFolderModalProps {
  onOk: (value: CreateFolderRequest) => void;
  onCancel: () => void;
  visible: boolean;
  currentPath: string[];
  parentPermissions?: FolderPermission[];
  usePermissions?: boolean;
  confirmLoading?: boolean;
  groupId?: string;
  parentReferenceId?: string;
}

export const NewFolderModal: React.FC<NewFolderModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    visible,
    onOk,
    onCancel,
    currentPath,
    parentPermissions,
    usePermissions,
    confirmLoading,
    groupId,
    parentReferenceId,
  } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [form] = Form.useForm<CreateFolderRequest>();
  const [input, setInput] = useState('');
  const [checkingName, setCheckingName] = useState<boolean>(false);
  const [nameExists, setNameExists] = useState<boolean>(false);
  const [nameIsValid, setNameIsValid] = useState<boolean>(true);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = (value: CreateFolderRequest) => {
    onOk(value);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setNameExists(false);
    setNameIsValid(true);
    form.resetFields();
    onCancel();
    setInput('');
  };

  const handleChange = (value: string) => {
    setInput(value);
    const isValid = !!value.match(folderNameRegex) && value.trim().length > 0;
    if (isValid) {
      setCheckingName(true);
      debouncedInputChange(value, async (debouncedValue) => {
        const fileExists = await checkFileNameExists(
          groupId,
          parentReferenceId,
          debouncedValue
        );
        setNameExists(fileExists);
        setCheckingName(false);
      });
      setNameIsValid(true);
    } else {
      setNameExists(false);
      setNameIsValid(false);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Modal
      className={classes.root}
      title={t('settings:projectFileSystemStructureEditor.newFolder.title')}
      okText={t('settings:projectFileSystemStructureEditor.newFolder.okText')}
      cancelText={t(
        'settings:projectFileSystemStructureEditor.newFolder.cancelText'
      )}
      onOk={handleOk}
      onClose={handleCancel}
      visible={visible}
      destroyOnClose
      cancelButtonProps={{ disabled: confirmLoading }}
      okButtonProps={{
        loading: confirmLoading || checkingName,
        disabled:
          confirmLoading ||
          !!!input ||
          !input.match(folderNameRegex) ||
          nameExists,
      }}
    >
      <NewFolderForm
        parentPermissions={parentPermissions}
        currentPath={currentPath}
        form={form}
        nameExists={nameExists}
        isValid={nameIsValid}
        usePermissions={usePermissions}
        onFinish={handleFinish}
        handleChange={handleChange}
      />
    </Modal>
  );
};

export default NewFolderModal;
