import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Divider, Form, FormInstance } from 'antd';
import Flex from '../../../components/Flex';
import { FolderPermissionValue } from '../../../models/Types';
import FolderPermissionSelect from './FolderPermissionSelect';
import {
  FolderPermission,
  FolderPermissionAccessRight,
  FolderPermissionRole,
  FolderPermissionV2,
} from '../../../models/Settings';
import { selectValuesToApiData } from '../../documents/util';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Input } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.spacing.small,
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      marginLeft: theme.spacing.large,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  label: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.small,
  },
  fullWidth: {
    width: '100%',
  },
  invalidName: {
    color: theme.colors.base.red.default,
    margin: 0,
  },
}));

const permissionsToValue: (
  permissions: FolderPermission[],
  accessRight: FolderPermissionAccessRight
) => FolderPermissionValue = (permissions, accessRight) => {
  const roles = permissions.find(
    (permission) => permission.accessRight === accessRight
  )?.roles;
  if (roles?.includes('write')) {
    return 'write';
  } else if (roles?.includes('read')) {
    return 'read';
  }
  return 'none';
};

export const valueToPermission: (
  value: FolderPermissionValue,
  accessRight: FolderPermissionAccessRight
) => FolderPermission | null = (value, accessRight) => {
  if (value === 'none') {
    return null;
  }
  const roles: FolderPermissionRole[] =
    value === 'write' ? ['read', 'write'] : ['read'];
  return {
    accessRight,
    roles,
  };
};

declare type FolderPermissionArray = [
  FolderPermissionValue,
  FolderPermissionValue,
  FolderPermissionValue,
  FolderPermissionValue,
  FolderPermissionValue,
];

export interface CreateFolderRequest {
  folderName: string;
  permissions: FolderPermissionArray | [];
}

interface NewFolderFormProps {
  parentPermissions?: FolderPermission[];
  usePermissions?: boolean;
  currentPath: string[];
  form: FormInstance<CreateFolderRequest>;
  nameExists?: boolean;
  isValid?: boolean;
  onFinish: (value: CreateFolderRequest) => void;
  handleChange?: (value: string) => void;
}

export const NewFolderForm: React.FC<NewFolderFormProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    usePermissions,
    parentPermissions,
    currentPath,
    form,
    nameExists,
    isValid,
    onFinish,
    handleChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const initialValues: CreateFolderRequest = useMemo(
    () => ({
      folderName: '',
      permissions: parentPermissions
        ? [
            permissionsToValue(parentPermissions, 'admin'),
            permissionsToValue(parentPermissions, 'assistance'),
            permissionsToValue(parentPermissions, 'controller'),
            permissionsToValue(parentPermissions, 'member'),
            permissionsToValue(parentPermissions, 'guest'),
          ]
        : [],
    }),
    [parentPermissions]
  );

  const [permissions, setPermissions] = useState<FolderPermissionV2[]>([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = async (value: CreateFolderRequest) => {
    const _permissions = permissions.map((permission) =>
      permission.canWrite
        ? ('write' as FolderPermissionValue)
        : permission.canRead
        ? ('read' as FolderPermissionValue)
        : ('none' as FolderPermissionValue)
    ) as FolderPermissionArray;
    const _value = { ...value, permissions: _permissions };

    onFinish(_value);
    form.resetFields();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.setFieldsValue(initialValues);
    inputRef.current?.focus();
  }, [initialValues, form]);
  //#endregion

  return (
    <Form<CreateFolderRequest>
      initialValues={initialValues}
      form={form}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Flex.Row>
        <Flex.Column className={classes.fullWidth}>
          <Form.Item
            name="folderName"
            label={t(
              'settings:projectFileSystemStructureEditor.newFolder.content',
              {
                fileName:
                  currentPath.slice(-1)[0] ??
                  t(
                    'settings:projectFileSystemStructureEditor.newFolder.rootFolder'
                  ),
              }
            )}
            style={{ marginBottom: '8px' }}
          >
            <Input
              ref={inputRef}
              className={classes.marginTop}
              full
              onChange={(value) => handleChange(value)}
            />
          </Form.Item>
          {(!isValid || nameExists) && (
            <Form.Item className={classes.invalidName}>
              {t(
                `settings:projectFileSystemStructureEditor.newFolder.${
                  !isValid ? 'invalidFolderName' : 'folderNameExists'
                }`
              )}
            </Form.Item>
          )}
        </Flex.Column>
      </Flex.Row>
      {usePermissions && (
        <Flex.Row>
          <Flex.Column className={classes.fullWidth}>
            <Form.Item name="permissions" style={{ marginBottom: 0 }}>
              <FolderPermissionSelectField setPermissions={setPermissions} />
            </Form.Item>
          </Flex.Column>
        </Flex.Row>
      )}
    </Form>
  );
};

export default NewFolderForm;

interface FolderPermissionSelectFieldProps {
  setPermissions: (permissions: FolderPermissionV2[]) => void;
}

const FolderPermissionSelectField: React.FC<
  FolderPermissionSelectFieldProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { setPermissions } = props;
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [assistancePermission, setAssistancePermission] =
    useState<FolderPermissionValue>('write');

  const [controllerPermission, setControllerPermission] =
    useState<FolderPermissionValue>('write');

  const [memberPermission, setMemberPermission] =
    useState<FolderPermissionValue>('write');

  const [guestPermission, setGuestPermission] =
    useState<FolderPermissionValue>('none');

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setPermissions([
      selectValuesToApiData('admin', 'write'),
      selectValuesToApiData('assistance', assistancePermission),
      selectValuesToApiData('controller', controllerPermission),
      selectValuesToApiData('member', memberPermission),
      selectValuesToApiData('guest', guestPermission),
    ]);
  }, [
    assistancePermission,
    controllerPermission,
    memberPermission,
    guestPermission,
    setPermissions,
  ]);
  //#endregion

  return (
    <Collapse ghost className={classes.collapse}>
      <Collapse.Panel
        header={
          <Flex.Row flex={1}>
            <div className={classes.label}>
              {t(
                'settings:projectFileSystemStructureEditor.panel.folderPermission'
              )}
            </div>
            <Divider className={classes.divider} />
          </Flex.Row>
        }
        key="1"
      >
        <Flex.Column childrenGap={theme.old.spacing.baseSpacing}>
          <FolderPermissionSelect
            field={'admin'}
            value={'write'}
            showTitle
            disabled
          />
          <FolderPermissionSelect
            field={'assistance'}
            value={assistancePermission}
            onChange={(value) => setAssistancePermission(value)}
            showTitle
          />
          <FolderPermissionSelect
            field={'controller'}
            value={controllerPermission}
            onChange={(value) => setControllerPermission(value)}
            showTitle
          />
          <FolderPermissionSelect
            field={'member'}
            value={memberPermission}
            onChange={(value) => setMemberPermission(value)}
            showTitle
          />
          <FolderPermissionSelect
            field={'guest'}
            value={guestPermission}
            onChange={(value) => setGuestPermission(value)}
            showTitle
          />
        </Flex.Column>
      </Collapse.Panel>
    </Collapse>
  );
};
