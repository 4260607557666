import { Button, Drawer } from '@prio365/prio365-react-library';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTemplatePreview } from '../DocumentTemplateFrom/DocumentTemplatePreview';
import DocumentTemplateFormWrapper, {
  DocumentTemplateFormWrapperRef,
} from '../DocumentTemplateFrom/DocumentTemplateFormWrapper';
import { DriveItem } from '../../../../models/Drive';
import DocumentTemplateTagContextProvider from '../DocumentTemplateTagContextProvider';
import DocumentTemplateCategoryContextProvider from '../DocumentTemplateCategoryContextProvider';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import { FilterBar } from '../../../../components/Filter/FilterBar';
import { ProjectId } from '../../../../models/Types';
import {
  checkFileNameExists,
  debouncedInputChange,
  fileNameRegex,
} from '../../util';

interface NewDocumentFromTemplateDrawerProps {
  visible?: boolean;
  driveItem: DriveItem;
  projectId: string;
  groupId: string;
  onCancel?: () => void;
  onSuccess?: (driveItem?: DriveItem, projectId?: ProjectId) => void;
}

export const NewDocumentFromTemplateDrawer: React.FC<
  NewDocumentFromTemplateDrawerProps
> = (props) => {
  const {
    visible = false,
    onCancel = () => {},
    onSuccess = () => {},
    driveItem,
    projectId,
    groupId,
  } = props;
  //#region ------------------------------------------------- Defaults
  const { t } = useTranslation();
  const formWrapperRef = useRef<DocumentTemplateFormWrapperRef>(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [thumbnailURL, setThumbnailURL] = useState<String[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [nameExists, setNameExists] = useState<boolean>(false);
  const [nameIsValid, setNameIsValid] = useState<boolean>(true);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  function handleClose() {
    setNameExists(false);
    setNameIsValid(true);
    setSelectedTemplate({});
    setThumbnailURL([]);
    onCancel();
  }

  const handleDocumentNameChange = (
    value: string,
    parentReferenceId: string
  ) => {
    const isValid = !!value.match(fileNameRegex) && value.trim().length > 0;
    setNameIsValid(isValid);

    if (isValid) {
      debouncedInputChange(value, async (debouncedValue) => {
        const fileExists = await checkFileNameExists(
          groupId,
          parentReferenceId,
          debouncedValue
        );
        setNameExists(fileExists);
      });
    } else {
      setNameExists(false);
      //setNameIsValid(false);
    }
  };
  //#endregion
  return (
    <Drawer
      zIndex={1000}
      title={t('documents:navigationBar.createDocument')}
      visible={visible}
      onClose={handleClose}
      customWidth={800}
      closable={true}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button type="default" onClick={handleClose}>
            {t('common:actions.cancel')}
          </Button>
          <Button
            onClick={() => formWrapperRef.current?.onFinish()}
            disabled={!isFormValid || nameExists || !nameIsValid}
            style={{
              marginLeft: '12px',
            }}
          >
            {t('common:actions.save')}
          </Button>
        </div>
      }
      mask={
        <DocumentTemplatePreview
          thumbnailURL={thumbnailURL}
          selectedTemplate={selectedTemplate}
        />
      }
    >
      <DocumentTemplateTagContextProvider>
        <DocumentTemplateCategoryContextProvider>
          <FilterContextProvider
            options={{
              enabled: false,
              staleTime: 1000 * 60 * 60 * 24,
            }}
            searchType="documentTemplates"
            customDefaultSearchParameters={[
              {
                parameterName: 'Data.IsReport',
                defaultValue: 'false',
                defaultMethod: 'eq',
              },
            ]}
          >
            <FilterBar hiddenPickers={['Data.IsReport']} />
            <DocumentTemplateFormWrapper
              parentFolderDriveItem={driveItem}
              projectId={projectId}
              groupId={groupId}
              onCancel={handleClose}
              onSuccess={onSuccess}
              onThumbnailURLChange={setThumbnailURL}
              onSelectedTemplateChange={setSelectedTemplate}
              showOnlyReports={false}
              projectPickerFilter={(project) => !project.isArchived}
              ref={formWrapperRef}
              setIsFormValid={setIsFormValid}
              handleDocumentNameChange={handleDocumentNameChange}
              nameExists={nameExists}
              nameIsValid={nameIsValid}
            />
          </FilterContextProvider>
        </DocumentTemplateCategoryContextProvider>
      </DocumentTemplateTagContextProvider>
    </Drawer>
  );
};
