import { GenericSearchResultItem } from '../components/Filter/types';
import { PreviewListItem } from '../modules/documents/reducers/previewModal';
import {
  DateTimeString,
  DriveItemId,
  DriveItemListItemId,
  GroupId,
  ProjectId,
} from './Types';

//update children to value
export interface DriveItemFolderDto {
  item?: DriveItem;
  nextLink?: string;
  children?: DriveItem[];
}

export interface DriveItem {
  id: DriveItemId;
  description?: string;
  file?: File;
  folder?: Folder;
  lastModifiedDateTime: DateTimeString; //relative
  lastModifiedBy?: {
    user: Identity;
  };
  listItemFields: ListItemFields;
  name?: string;
  size?: number;
  package?: {
    type: string;
  };
  parentReference?: {
    path?: string;
    id: string;
    driveId?: string;
    driveType?: string;
    name?: string;
  };
  sharepointIds?: {
    listId: string;
    listItemId?: DriveItemListItemId;
    listItemUniqueId?: string;
    siteId: string;
    siteUrl: string;
    tenantId: string;
    webId: string;
    additionalData?: any;
    oDataType: string;
  };
  thumbnails?: Thumbnail[];
  webUrl?: string;
  webDavUrl?: string;
}

export const DEFAULT_PRIO_COLUMN_PREFIX = 'Prio365';

const listItemFieldColumnNames = [
  `${DEFAULT_PRIO_COLUMN_PREFIX}AlternativeName`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}SharedId`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}Description`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}CompanyIds`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}ContactIds`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}AttachedMessageImmutableId`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}AttachedMessageId`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}AttachedMessageAttachmentId`,
  `${DEFAULT_PRIO_COLUMN_PREFIX}DriveItemTags`,
] as const;

export type ListItemFieldColumnName = (typeof listItemFieldColumnNames)[number];

export type ListItemFields = { [C in ListItemFieldColumnName]?: string[] };

export interface Thumbnail {
  id: string;
  large: ThumbnailSet;
  medium: ThumbnailSet;
  small: ThumbnailSet;
}

export interface ThumbnailSet {
  height: number;
  width: number;
  url: string;
}

export interface Identity {
  id?: string;
  displayName?: string;
}

export interface Folder {
  childCount?: number;
  displayName?: string;
  test?: {
    toffifeee: string;
  };
}

export interface File {
  mimeType?: string;
}

export interface CopyDriveItem {
  driveItemId: DriveItemId;
  name: string;
  deleteSourceDriveItem: boolean;
}

export interface FolderDriveItem {
  groupId: GroupId;
  projectId: ProjectId;
  driveItem: DriveItem;
}

export interface DriveItemDropTarget {
  type: DriveDropTargetType;
  object: object;
}

export type DriveDropTargetType = 'project' | 'remoteDriveItem' | 'driveItem';

export type FileConvertFormat = 'pdf';

// See https://docs.microsoft.com/en-us/graph/api/driveitem-get-content-format?view=graph-rest-beta&tabs=http
export const allowedFileTypesForPdfConversion = [
  //'eml',
  'epub',
  'htm',
  'html',
  'md',
  'msg',
  'doc',
  'docx',
  'pps',
  'ppsx',
  'ppt',
  'pptx',
  'odp',
  'ods',
  'odt',
  'rtf',
  'tif',
  'tiff',
  /*
  'xls',
  'xlsm',
  'xlsx',*/
];

export interface UploadSession {
  expirationDateTime: DateTimeString;
  nextExpectedRanges: string[];
  uploadUrl: string;
  [key: string]: any;
}

export interface UpdateDriveItemField {
  groupId: GroupId;
  updateDriveItemFieldDtos: Array<{
    listitemId?: DriveItemListItemId;
    driveItemId: DriveItemId;
    columnName: ListItemFieldColumnName;
    values: string[];
  }>;
}

export declare type UpdateDriveItemFieldError = Array<{
  listItemId?: DriveItemListItemId;
  driveItemId: DriveItemId;
  columnName: ListItemFieldColumnName;
  value: string;
}>;

export interface Preview {
  type: 'image' | 'iframe';
  src: string;
  item: PreviewListItem;
  mimeType: string;
  optional?: {
    localDriveItemUrl?: string;
    driveItemWebUrl?: string;
    lastModifiedDateTime?: string;
  };
}

export interface DriveItemCalculatedData {
  groupId: GroupId;
  projectIds: ProjectId[];
  siteName: string;
  path: string;
}

export type DocumentsSearchResultItem = GenericSearchResultItem<
  DriveItem,
  DriveItemCalculatedData
>;

export interface CheckFileName {
  parentFolderId: string;
  fileNames: string[];
}

export interface CheckedFileName {
  fileName: string;
  exists: boolean;
}

export interface FileNameChecked {
  parentFolderId: string;
  fileNames: CheckedFileName[];
}
export interface FileNamesChecked {
  fileNameCheckedDtos: FileNameChecked[];
}
export interface CheckFileNames {
  checkFileNameDtos: CheckFileName[];
}
