import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal, notification, Typography } from 'antd';
import { DriveItem } from '../../../../models/Drive';
import {
  checkFileNameExists,
  debouncedInputChange,
  fileNameRegex,
  isDriveItemFolder,
} from '../../util';
import { apiUpdateDriveItem } from '../../api';
import { useDispatch } from 'react-redux';
import { driveItemRenamed } from '../../actions';
import { makePrioStyles } from '../../../../theme/utils';
import { DriveItemId, GroupId } from '../../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import Flex from '../../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.old.spacing.unit(1),
  },
}));

interface RenameModalProps {
  onClose: VoidFunction;
  renameDriveItem: DriveItem;
  folderDriveItemId: DriveItemId;
  groupId: GroupId;
}

export const RenameModal: React.FC<RenameModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const { groupId, renameDriveItem, folderDriveItemId, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [renameValue, setRenameValue] = useState<string>(null);
  const [fileEnding, setFileEnding] = useState<string>(null);
  const [renameValid, setRenameValid] = useState<boolean>(true);
  const [renamingActive, setRenamingActive] = useState<boolean>(false);
  const [checkingName, setCheckingName] = useState<boolean>(false);
  const [nameExists, setNameExists] = useState<boolean>(false);

  const isInitialValue =
    renameValue?.trim() ===
    (isDriveItemFolder(renameDriveItem)
      ? renameDriveItem?.name
      : renameDriveItem?.name.split('.').slice(0, -1).join('.'));
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onRenameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _renameValue = e.target.value;
    setRenameValue(_renameValue);
    const _value = `${_renameValue.trim()}${
      fileEnding ? `.${fileEnding}` : ''
    }`;
    const isValid =
      !!_value.match(fileNameRegex) && _renameValue.trim().length > 0;
    setRenameValid(isValid);

    if (isValid) {
      setCheckingName(true);
      debouncedInputChange(_value, async (debouncedValue) => {
        const fileExists = await checkFileNameExists(
          groupId,
          renameDriveItem?.parentReference?.id,
          debouncedValue
        );
        setNameExists(fileExists);
        setCheckingName(false);
      });
    } else {
      setNameExists(false);
    }
  };

  const onRenameOk = async () => {
    const _value = `${renameValue.trim()}${fileEnding ? `.${fileEnding}` : ''}`;
    if (_value === renameDriveItem.name) {
      closeModal();
      return;
    }
    setRenamingActive(true);
    const { result, data } = await apiUpdateDriveItem(
      groupId,
      renameDriveItem.id,
      { name: _value }
    );
    setRenamingActive(false);
    if (result.status >= 200 && result.status < 300) {
      dispatch(driveItemRenamed(data, folderDriveItemId, groupId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.renameDocumentError'),
      });
    }
    closeModal();
  };

  const closeModal = () => {
    setRenameValue(null);
    setFileEnding(null);
    onClose();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (renameDriveItem) {
      const splittedNameArray = renameDriveItem.name.split('.');
      if (
        isDriveItemFolder(renameDriveItem) ||
        splittedNameArray.length === 1
      ) {
        setRenameValue(renameDriveItem.name);
        setFileEnding('');
      } else {
        setRenameValue(splittedNameArray.slice(0, -1).join('.'));
        setFileEnding(splittedNameArray.pop() || '');
      }
    }
  }, [renameDriveItem]);
  //#endregion

  return (
    <Modal
      title={t('documents:confirmation.rename.title')}
      visible={!!renameDriveItem}
      onOk={onRenameOk}
      onCancel={closeModal}
      confirmLoading={renamingActive}
      okButtonProps={{
        loading: (checkingName || renamingActive) && !isInitialValue,
        disabled: !renameValid || nameExists || checkingName || isInitialValue,
      }}
      okText={t('documents:confirmation.rename.okText')}
      cancelText={t('documents:confirmation.rename.cancelText')}
      className={classes.root}
    >
      {renameDriveItem && (
        <div>
          <div>
            {t('documents:confirmation.rename.content', {
              fileName: renameDriveItem.name,
            })}
          </div>
          <Flex.Row
            alignItems="baseline"
            childrenGap={theme.old.spacing.unit(1)}
          >
            <Input
              value={renameValue}
              onChange={onRenameInputChange}
              className={classes.marginTop}
            />
            <Typography.Text>
              {fileEnding ? `.${fileEnding}` : ''}
            </Typography.Text>
          </Flex.Row>
          {(!renameValid || nameExists) && !isInitialValue && (
            <div
              style={{
                color: 'red',
                marginTop: '8px',
              }}
            >
              {t(
                `documents:confirmation.rename.${
                  !renameValid ? 'invalidFileName' : 'nameExists'
                }`
              )}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default RenameModal;
